import React from "react";

const TourPackages = () => {
    return (
        <div>
            <h3>List your tour packages here</h3>
            <p className="tw-text-sm">
                List generic tour packages or packages that customers can join immediately.
            </p>
        </div>
    )
}

export default TourPackages;